import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import SetPasswordGuard from '../auth/SetPasswordGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  ChangePasswordPage,
  VerifyCodePage,
  PhoneVerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  SetPasswordPage,
  // Dashboard: General
  General3DModelPage,
  GeneralManageAppThumbnailPage,
  // Dashboard: User
  GeneralUserListPage,
  GeneralShopperListPage,
  GeneralAddCompanyPage,
  GeneralUserAccountPage,
  GeneralUserAccountEditPage,
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Product
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  // Category
  EcommerceCategoryPage,
  EcommerceCategoryEditPage,
  EcommerceCategoryCreatePage,
  // Dashboard: Billing
  GeneralBillingPage,
  // GeneralBroadcastSystem
  GeneralBroadcastSystemPage,

  // Dashboard: Subscription
  GeneralSubscriptionPage,
  GeneralSubscriptionEditPage,
  GeneralSubscriptionCreatePage,
  // Dashboard: Blog
  GeneralBlogPage,
  GeneralDraftBlogPage,
  GeneralBlogEditPage,
  GeneralBlogCreatePage,
  // Dashboard: QA
  GeneralQAPage,
  GeneralQAEditPage,
  GeneralQACreatePage,
  GeneralQACategoryPage,
  GeneralQACategoryCreatePage,
  GeneralQACategoryEditPage,
  // Dashboard: ED
  GeneralEDPage,
  GeneralDraftEDPage,
  GeneralEDEditPage,
  GeneralEDCreatePage,
  // Tag
  EcommerceTagPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Pricing
  PricingListPage,
  PricingCreatePage,
  PricingEditPage,
  // Dashboard: Service
  ServiceListPage,
  ServiceCreatePage,
  ServiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,

  // Dashboard: Media
  GeneralMediaManagerPage,
  GeneralMediaGalleryImageAddPage,
  GeneralMediaGalleryImageEditPage,
  GeneralMediaVideoEditPage,

  // AssetRequest
  GeneralAssetRequestListPage,
  GeneralAssetRequestEditPage,
  AssetRequestDetailsPage,
  // Dashboard: FileManager
  GeneralFileManagerPage,
  //
  PermissionDeniedPage,
  UpgradePlanPage,
  UserUpgradingFromProTrialPage,
  SwitchBillingPage,
  AlmostDonePage,
  PaymentMethodsPage,

  // Congratulations
  UpgradePlanCongratulationPage,
  UpgradeBillingCongratulationPage,
  UpgradePaymentCongratulationPage,
  UpgradeAccountCongratulationPage,
  UpgradeUserInfoCongratulationPage,
  //
  Page404,
  AddAdminPage,
  AddCompaniesPage,
  AffiliatesManagePage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        {
          path: 'setPassword',
          element: (
            <SetPasswordGuard>
              <SetPasswordPage />
            </SetPasswordGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
            { path: 'phoneVerify', element: <PhoneVerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'fences', element: <General3DModelPage /> },
        {
          path: 'media',
          children: [
            { element: <Navigate to="/dashboard/media/manage" replace />, index: true },
            { path: 'manage', element: <GeneralMediaManagerPage /> },
            { path: 'video', element: <GeneralMediaVideoEditPage /> },
            { path: 'gallery', element: <GeneralMediaGalleryImageEditPage /> },
            { path: 'newimages', element: <GeneralMediaGalleryImageAddPage /> },
          ],
        },
        {
          path: 'billing',
          element: <GeneralBillingPage />,
        },
        {
          path: 'billing/:id',
          element: <InvoiceDetailsPage />,
        },
        {
          path: 'assetRequest',
          children: [
            { element: <Navigate to="/dashboard/assetRequest/list" replace />, index: true },
            { path: 'list', element: <GeneralAssetRequestListPage /> },
            { path: 'edit/:id', element: <GeneralAssetRequestEditPage /> },
            { path: 'view/:id', element: <AssetRequestDetailsPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <GeneralUserListPage /> },
            { path: 'shopper', element: <GeneralShopperListPage /> },
            { path: 'newcompany', element: <GeneralAddCompanyPage /> },
            { path: 'account/:id', element: <GeneralUserAccountPage /> },
          ],
        },
        {
          path: 'subscription',
          children: [
            { element: <Navigate to="/dashboard/subscription/list" replace />, index: true },
            { path: 'list', element: <GeneralSubscriptionPage /> },
            { path: 'new', element: <GeneralSubscriptionCreatePage /> },
            { path: 'edit/:id', element: <GeneralSubscriptionEditPage /> },
          ],
        },

        {
          path: 'ED',
          children: [
            { element: <Navigate to="/dashboard/ED/list" replace />, index: true },
            { path: 'list', element: <GeneralEDPage /> },
            { path: 'draft', element: <GeneralDraftEDPage /> },
            { path: 'new', element: <GeneralEDCreatePage /> },
            { path: 'edit/:id', element: <GeneralEDEditPage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/list" replace />, index: true },
            { path: 'list', element: <GeneralBlogPage /> },
            { path: 'draft', element: <GeneralDraftBlogPage /> },
            { path: 'new', element: <GeneralBlogCreatePage /> },
            { path: 'edit/:id', element: <GeneralBlogEditPage /> },
          ],
        },
        {
          path: 'QA',
          children: [
            { element: <Navigate to="/dashboard/QA/list" replace />, index: true },
            { path: 'list', element: <GeneralQAPage /> },
            { path: 'new', element: <GeneralQACreatePage /> },
            { path: 'edit/:id', element: <GeneralQAEditPage /> },
            { path: 'category', element: <GeneralQACategoryPage /> },
            { path: 'newcategory', element: <GeneralQACategoryCreatePage /> },
            { path: 'editcategory/:id', element: <GeneralQACategoryEditPage /> },
          ],
        },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'product/new', element: <EcommerceProductCreatePage /> },
            { path: 'product/:id/edit', element: <EcommerceProductEditPage /> },
            { path: 'newcategory', element: <EcommerceCategoryCreatePage /> },
            { path: 'category', element: <EcommerceCategoryPage /> },
            { path: 'category/:id/edit', element: <EcommerceCategoryEditPage /> },
            { path: 'thumbnail/:title', element: <GeneralManageAppThumbnailPage /> },
            { path: 'tag', element: <EcommerceTagPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: 'edit/:id', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'service',
          children: [
            { element: <Navigate to="/dashboard/service/list" replace />, index: true },
            { path: 'list', element: <ServiceListPage /> },
            { path: 'new', element: <ServiceCreatePage /> },
            { path: 'edit/:id', element: <ServiceEditPage /> },
          ],
        },
        {
          path: 'pricing',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <PricingListPage /> },
            { path: ':id/edit', element: <PricingEditPage /> },
            { path: 'new', element: <PricingCreatePage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPostsPage /> },
            { path: 'post/:title', element: <BlogPostPage /> },
            { path: 'new', element: <BlogNewPostPage /> },
          ],
        },
        { path: 'file', element: <GeneralFileManagerPage /> },

        {
          path: 'broadcast',
          element: <GeneralBroadcastSystemPage />,
        },
        {
          path: 'affiliates',
          element: <AffiliatesManagePage />,
        },
        { path: 'addAdmin', element: <AddAdminPage /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
      ],
    },
    {
      path: 'upgradePlan/:id',
      element: (
        <AuthGuard>
          <UpgradePlanPage />
        </AuthGuard>
      ),
    },
    {
      path: 'upgradingfromprotrial/:id',
      element: (
        <AuthGuard>
          <UserUpgradingFromProTrialPage />
        </AuthGuard>
      ),
    },
    {
      path: 'changePassword',
      element: (
        <AuthGuard>
          <ChangePasswordPage />
        </AuthGuard>
      ),
    },
    {
      path: 'almostdone/:id',
      element: (
        <AuthGuard>
          <AlmostDonePage />
        </AuthGuard>
      ),
    },
    {
      path: 'switchbilling/:id',
      element: (
        <AuthGuard>
          <SwitchBillingPage />
        </AuthGuard>
      ),
    },
    {
      path: 'paymentmethods/:id',
      element: (
        <AuthGuard>
          <PaymentMethodsPage />
        </AuthGuard>
      ),
    },
    {
      path: 'account/:id',
      element: (
        <AuthGuard>
          <GeneralUserAccountEditPage />
        </AuthGuard>
      ),
    },
    {
      path: 'addComapnies/:id',
      element: (
        <AuthGuard>
          <AddCompaniesPage />
        </AuthGuard>
      ),
    },
    //   ],
    // },

    // Main Routes
    { path: '/', element: <LoginPage /> },
    { path: '/upgradeSuccess', element: <UpgradePlanCongratulationPage /> },
    { path: '/billingSuccess', element: <UpgradeBillingCongratulationPage /> },
    { path: '/paymentsuccess', element: <UpgradePaymentCongratulationPage /> },
    { path: '/upgradeAccountSuccess', element: <UpgradeAccountCongratulationPage /> },
    { path: '/upgradeUserInfoSuccess', element: <UpgradeUserInfoCongratulationPage /> },
    { path: '*', element: <Page404 /> },
  ]);
}
